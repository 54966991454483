/*
 * XRXAccessConfig.js
 * Copyright (C) Xerox Corporation, 2020.  All rights reserved.
 * 
 * This file contains the functions to make Access Configuration Web service calls.
 *
 * @revision	02/21/2020	TC	Initial version.
 *
 */
 
var XRXAccessConfig = (function() { 

	var XRX_ACCESSCONFIG_ACTION = 'http://www.xerox.com/webservices/office/accessconfig/1';
	var XRX_ACCESSCONFIG_NAMESPACE = 'xmlns="' + XRX_ACCESSCONFIG_ACTION + '"';
	var XRX_ACCESSCONFIG_PATH = '/webservices/office/accessconfig/1';
	
	var DEFAULT_URL = "http://127.0.0.1";
	
	var methods = {
 
		// GetInterfaceVersion()
		/**
		* This function gets the interface version and returns the parsed values.
		*
		* @function xrxAccessConfigGetInterfaceVersion
		* @param {Object} params - The parameters for calling GetInterfaceVersion.
		* @param {string} params.url - The device address.
		* @param {string} params.callback_success - function to callback upon successful completion
		* @param {string} params.callback_failure - function to callback upon failed completion
		* @param {number} [params.timeout=0] - amount of seconds to wait before calling 
		*									the callback_failure routine (0 = no timeout)
		* @return {string} Blank string or comm error beginning with "FAILURE"
		*
		*/
		xrxAccessConfigGetInterfaceVersion: function( params ) {
			var url = params.url || DEFAULT_URL;
			var sendUrl = url + XRX_ACCESSCONFIG_PATH;
			var sendReq = this.xrxAccessConfigGetInterfaceVersionRequest( sendUrl );
			return xrxCallWebservice( sendUrl, sendReq, params.callback_success, params.callback_failure, 
						params.timeout, null, null, null, true );
		},

		/**
		* This function builds the interface version request.
		*
		* @return {string}	xml request
		*/
		xrxAccessConfigGetInterfaceVersionRequest: function( sendUrl ) {
			var result = XRX_SOAP11_SOAPSTART 
					+ xrxCreateTag( 'GetInterfaceVersionRequest', XRX_ACCESSCONFIG_NAMESPACE, '' ) 
					+ XRX_SOAPEND;
			var action = XRX_ACCESSCONFIG_ACTION + "/GetInterfaceVersion";
			var to = sendUrl + "/";
			try {
				result = addWsAddressingHeader( result, action, to );
			} catch(err) {
				console.log("Error in addWsAddressingHeader()" + err.message);
			}
			return result;
		},
		
		// GetAccessConfigInfo()
		/**
		* This function gets AccessConfigInfo
		*
		* @function xrxAccessConfigGetAccessConfigInfo
		* @param {Object} params - The parameters for calling GetInterfaceVersion.
		* @param {string} params.url - The device address.
		* @param {string} params.callback_success - function to callback upon successful completion
		* @param {string} params.callback_failure - function to callback upon failed completion
		* @param {number} [params.timeout=0] - amount of seconds to wait before calling 
		*									the callback_failure routine (0 = no timeout)
		* @return {string} Blank string or comm error beginning with "FAILURE"
		*
		*/		
		xrxAccessConfigGetAccessConfigInfo: function( params ) {
			var url = params.url || DEFAULT_URL;
			var sendUrl = url + XRX_ACCESSCONFIG_PATH;
			var sendReq = this.xrxAccessConfigGetAccessConfigInfoRequest( sendUrl );
			return xrxCallWebservice( sendUrl, sendReq, params.callback_success, params.callback_failure, 
						params.timeout, null, null, null, true );
		},

		/**
		* This function returns the GetAccessConfigInfoRequest
		*
		* @param {string}	sendUrl	The device address with the Access Configuration path
		* @return {string}   The GetAccessConfigInfoRequest in string.
		*/		
		xrxAccessConfigGetAccessConfigInfoRequest: function( sendUrl ) {
			var result = XRX_SOAP11_SOAPSTART 
					+ xrxCreateTag( 'GetAccessConfigInfoRequest', XRX_ACCESSCONFIG_NAMESPACE, '' ) 
					+ XRX_SOAPEND;
			var action = XRX_ACCESSCONFIG_ACTION + "/GetAccessConfigInfo";
			var to = sendUrl + "/";
			try {
				result = addWsAddressingHeader( result, action, to );
			} catch(err) {
				console.log("Error in addWsAddressingHeader()" + err.message);
			}
			return result;
		},

		/**
		* This function returns the parsed values.
		*
		* @param {string}	response	web service response in string form
		* @return {array}   an array of AccessConfigInfo
		*/
		xrxAccessConfigParseGetAccessConfigInfo: function( response ) {
			var result = new Array();
			
			var list = xrxFindElements( xrxStringToDom( response ), "element" );
			if(list === null) {
				list = new Array();
			}
			var i = 0;
			for(i = 0; i < list.length; i++) {
				var AccessConfigInfo = {};
				AccessConfigInfo.ApplicationName = xrxGetElementValue( list[i], "ApplicationName" );
				AccessConfigInfo.AccessDisplay = xrxGetElementValue( list[i], "AccessDisplay" );
				AccessConfigInfo.AllowUserList = xrxGetElementValue( list[i], "AllowUserList" );
				result.push( AccessConfigInfo );
			}
			return result;
		},			

		// SetAccessConfigInfo
		
		/**
		* This function sets AccessConfigInfo
		*
		* @function xrxAccessConfigSetAccessConfigInfo
		* @param {Object} params - The parameters for calling SetAccessConfigInfo
		* @param {string} params.url - The device address.
		* @param {string} params.appName - The application to set the access information of.
		* @param {string} params.accessType - The new access type information of the application
		* @param {string} params.userList - The new AllowUserList of the application
		* @param {string} params.username	- Admin username or network authentication username.
		* @param {string} params.password	- Admin password or network authentication password.
		* @param {string} [params.passwordType="PasswordDigest"] - Ws-Security password type. 		
		* @param {string} params.callback_success - function to callback upon successful completion
		* @param {string} params.callback_failure - function to callback upon failed completion
		* @param {number} [params.timeout=0] - amount of seconds to wait before calling 
		*									the callback_failure routine (0 = no timeout)
		* @return {string} Blank string or comm error beginning with "FAILURE"
		*
		*/		
		xrxAccessConfigSetAccessConfigInfo: function( params ) {
			var url = params.url || DEFAULT_URL;
			var sendUrl = url + XRX_ACCESSCONFIG_PATH;
			var sendReq = this.xrxAccessConfigSetAccessConfigInfoRequest( sendUrl, params.appName, params.accessType, params.userList, params.username, params.password, params.passwordType );
			return xrxCallWebservice( sendUrl, sendReq, params.callback_success, params.callback_failure, 
						params.timeout, null, null, null, true );
		},

		/**
		* This function returns the AccessConfigInfo request
		*
		* @param {string} sendUrl	- The device address with the Access Configuration path
		* @param {string} appName - The application to set the access information of.
		* @param {string} accessType - The new access type information of the application
		* @param {string} userList - The new AllowUserList of the application
		* @param {string} username	- Admin username or network authentication username.
		* @param {string} password	- Admin password or network authentication password.
		* @param {string} [passwordType="PasswordDigest"] - Ws-Security password type.
		* @return {string}  The SetAccessConfigInfoRequest in string.
		*/		
		xrxAccessConfigSetAccessConfigInfoRequest: function( sendUrl, appName, accessType, userList, username, password, type ) {
			var result = XRX_SOAP11_SOAPSTART 
					+ xrxCreateTag( 'SetAccessConfigInfoRequest', XRX_ACCESSCONFIG_NAMESPACE, 
						xrxCreateTag( 'ApplicationName', '', appName ) +
						xrxCreateTag( 'AccessInfo', '', (xrxCreateTag( 'AccessDisplay', '', accessType) + xrxCreateTag( 'AllowUserList', '', userList)) ) )
					+ XRX_SOAPEND;
			var action = XRX_ACCESSCONFIG_ACTION + "/SetAccessConfigInfo";
			var to = sendUrl + "/";
			try {
				result = addWsSecurityHeader( result, username, password, type );
				result = addWsAddressingHeader( result, action, to );
			} catch(err) {
				console.log("Error in addWsSecurityHeader() or addWsAddressingHeader()" + err.message);
			}
			return result;
		},

		// GetAccountingMethodConfiguration
		
		/**
		* This function gets AccountingMethodConfiguration
		*
		* @function xrxAccessConfigGetAccountingMethodConfiguration
		* @param {Object} params - The parameters for calling GetAccountingMethodConfiguration
		* @param {string} params.url - The device address.
		* @param {string} params.callback_success - function to callback upon successful completion
		* @param {string} params.callback_failure - function to callback upon failed completion
		* @param {number} [params.timeout=0] - amount of seconds to wait before calling 
		*									the callback_failure routine (0 = no timeout)
		* @return {string} Blank string or comm error beginning with "FAILURE"
		*
		*/		
		xrxAccessConfigGetAccountingMethodConfiguration: function( params ) {
			var url = params.url || DEFAULT_URL;
			var sendUrl = url + XRX_ACCESSCONFIG_PATH;
			var sendReq = this.xrxAccessConfigGetAccountingMethodConfigurationRequest( sendUrl );
			return xrxCallWebservice( sendUrl, sendReq, params.callback_success, params.callback_failure, 
						params.timeout, null, null, null, true );
		},
		
		/**
		* This function returns the GetAccountingMethodConfigurationRequest
		*
		* @param {string}	sendUrl	The device address with the Access Configuration path
		* @return {string}   The GetAccountingMethodConfigurationRequest in string.
		*/		
		xrxAccessConfigGetAccountingMethodConfigurationRequest: function( sendUrl ) {
			var result = XRX_SOAP11_SOAPSTART 
					+ xrxCreateTag( 'VoidRequest', XRX_ACCESSCONFIG_NAMESPACE, '' ) 
					+ XRX_SOAPEND;
			var action = XRX_ACCESSCONFIG_ACTION + "/GetAccountingMethodConfiguration";
			var to = sendUrl + "/";
			try {
				result = addWsAddressingHeader( result, action, to );
			} catch(err) {
				console.log("Error in addWsAddressingHeader()" + err.message);
			}
			return result;
		},

		/**
		* This function returns the parsed values.
		*
		* @param {string}	response	web service response in string form
		* @return {string} A string of the AccessConfigSchema_AccountingMethodConfigurationPayload
		*/
		xrxAccessConfigParseGetAccountingMethodConfiguration: function( response ) {
			return xrxGetElementValue( xrxStringToDom( response ), "AccessConfigSchema_AccountingMethodConfigurationPayload" );
		},		

		// SetAccountingMethodConfiguration
		
		/**
		* This function sets AccountingMethodConfiguration
		*
		* @function xrxAccessConfigSetAccountingMethodConfiguration
		* @param {Object} params - The parameters for calling SetAccountingMethodConfiguration
		* @param {string} params.url - The device address.
		* @param {string} params.payload - AccountingMethodConfigurationPayload.
		* @param {string} params.username	- Admin username or network authentication username.
		* @param {string} params.password	- Admin password or network authentication password.
		* @param {string} [params.passwordType="PasswordDigest"] - Ws-Security password type. 
		* @param {string} params.callback_success - function to callback upon successful completion
		* @param {string} params.callback_failure - function to callback upon failed completion
		* @param {number} [params.timeout=0] - amount of seconds to wait before calling 
		*									the callback_failure routine (0 = no timeout)
		* @return {string} Blank string or comm error beginning with "FAILURE"
		*
		*/		
		xrxAccessConfigSetAccountingMethodConfiguration: function( params ) {
			var url = params.url || DEFAULT_URL;
			var sendUrl = url + XRX_ACCESSCONFIG_PATH;
			var sendReq = this.xrxAccessConfigSetAccountingMethodConfigurationRequest( sendUrl, params.payload, params.username, params.password, params.passwordType );
			return xrxCallWebservice( sendUrl, sendReq, params.callback_success, params.callback_failure, 
						params.timeout, null, null, null, true );
		},

		/**
		* This function returns the SetAccountingMethodConfigurationRequest
		*
		* @param {string} sendUrl - The device address with the Access Configuration path
		* @param {string} payload - AccountingMethodConfigurationPayload.
		* @param {string} username - Admin username or network authentication username.
		* @param {string} password - Admin password or network authentication password.
		* @param {string} [passwordType="PasswordDigest"] - Ws-Security password type.
		*
		* @return {string}   The SetAccountingMethodConfigurationRequest in string.
		*/		
		xrxAccessConfigSetAccountingMethodConfigurationRequest: function( sendUrl, payload, username, password, type ) {
			var result = XRX_SOAP11_SOAPSTART 
					+ xrxCreateTag( 'SetAccountingMethodConfigurationRequest', XRX_ACCESSCONFIG_NAMESPACE, 
						xrxCreateTag( 'AccessConfig_AccountingMethodConfigurationPayload', '', xrxEscapePayload(payload) )
					) 
					+ XRX_SOAPEND;
			var action = XRX_ACCESSCONFIG_ACTION + "/SetAccountingMethodConfiguration";
			var to = sendUrl + "/";
			try {
				result = addWsSecurityHeader( result, username, password, type);
				result = addWsAddressingHeader( result, action, to );
			} catch(err) {
				console.log("Error in addWsSecurityHeader() or addWsAddressingHeader()" + err.message);
			}
			return result;
		},	
		
		// GetAccessConfiguration

		/**
		* This function gets AccessConfiguration
		*
		* @function xrxAccessConfigGetAccessConfiguration
		* @param {Object} params - The parameters for calling GetAccessConfiguration
		* @param {string} params.url - The device address.
		* @param {string} params.callback_success - function to callback upon successful completion
		* @param {string} params.callback_failure - function to callback upon failed completion
		* @param {number} [params.timeout=0] - amount of seconds to wait before calling 
		*									the callback_failure routine (0 = no timeout)
		* @return {string} Blank string or comm error beginning with "FAILURE"
		*
		*/			
		xrxAccessConfigGetAccessConfiguration: function( params ) {
			var url = params.url || DEFAULT_URL;
			var sendUrl = url + XRX_ACCESSCONFIG_PATH;
			var sendReq = this.xrxAccessConfigGetAccessConfigurationRequest( sendUrl );
			return xrxCallWebservice( sendUrl, sendReq, params.callback_success, params.callback_failure, 
						params.timeout, null, null, null, true );
		},

		/**
		* This function returns the xrxAccessConfigGetAccessConfigurationRequest
		*
		* @param {string}	sendUrl	The device address with the Access Configuration path
		* @return {string}   The GetAccessConfigurationRequest in string.
		*/			
		xrxAccessConfigGetAccessConfigurationRequest: function( sendUrl ) {
			var result = XRX_SOAP11_SOAPSTART 
					+ xrxCreateTag( 'GetAccessConfigurationVoidRequest', XRX_ACCESSCONFIG_NAMESPACE, '' ) 
					+ XRX_SOAPEND;
			var action = XRX_ACCESSCONFIG_ACTION + "/GetAccessConfiguration";
			var to = sendUrl + "/";
			try {
				result = addWsAddressingHeader( result, action, to );
			} catch(err) {
				console.log("Error in addWsAddressingHeader()" + err.message);
			}
			return result;
		},

		/**
		* This function returns the parsed values.
		*
		* @param {string}	response	web service response in string form
		* @return {string} A string of the AccessConfigurationPayload
		*/
		xrxAccessConfigParseGetAccessConfiguration: function( response ) {
			return xrxGetElementValue( xrxStringToDom( response ), "AccessConfigSchema_AccessConfigurationPayload" );
		},	
		
		// SetAccessConfiguration

		/**
		* This function sets AccessConfiguration
		*
		* @function xrxAccessConfigSetAccessConfiguration
		* @param {Object} params - The parameters for calling SetAccessConfiguration
		* @param {string} params.url - The device address.
		* @param {string} params.payload - AccessConfigurationPayload.
		* @param {string} params.username	- Admin username or network authentication username.
		* @param {string} params.password	- Admin password or network authentication password.
		* @param {string} [params.passwordType="PasswordDigest"] - Ws-Security password type.
		* @param {string} params.callback_success - function to callback upon successful completion
		* @param {string} params.callback_failure - function to callback upon failed completion
		* @param {number} [params.timeout=0] - amount of seconds to wait before calling 
		*									the callback_failure routine (0 = no timeout)
		* @return {string} Blank string or comm error beginning with "FAILURE"
		*
		*/			
		xrxAccessConfigSetAccessConfiguration: function( params ) {
			var url = params.url || DEFAULT_URL;
			var sendUrl = url + XRX_ACCESSCONFIG_PATH;
			var sendReq = this.xrxAccessConfigSetAccessConfigurationRequest( sendUrl, params.payload, params.username, params.password, params.passwordType );
			return xrxCallWebservice( sendUrl, sendReq, params.callback_success, params.callback_failure, 
						params.timeout, null, null, null, true );
		},

		/**
		* This function returns the xrxAccessConfigGetAccessConfigurationRequest
		*
		* @param {string}	sendUrl	The device address with the Access Configuration path
		* @return {string}   The GetAccessConfigurationRequest in string.
		*/		
		xrxAccessConfigSetAccessConfigurationRequest: function( sendUrl, payload, username, password, type ) {
			var result = XRX_SOAP11_SOAPSTART 
					+ xrxCreateTag( 'SetAccessConfigurationRequest', XRX_ACCESSCONFIG_NAMESPACE, 
						xrxCreateTag( 'AccessConfig_AccessConfigurationPayload', '', xrxEscapePayload(payload) )
					) 
					+ XRX_SOAPEND;
			var action = XRX_ACCESSCONFIG_ACTION + "/SetAccessConfiguration";
			var to = sendUrl + "/";
			try {
				result = addWsSecurityHeader( result, username, password, type);
				result = addWsAddressingHeader( result, action, to );
			} catch(err) {
				console.log("Error in addWsSecurityHeader() or addWsAddressingHeader()" + err.message);
			}
			return result;
		},

		// GetApplicationFeatureAccessConfigInfo

		/**
		* This function gets AccessConfiguration
		*
		* @function xrxAccessConfigGetApplicationFeatureAccessConfigInfo
		* @param {Object} params - The parameters for calling GetApplicationFeatureAccessConfigInfo
		* @param {string} params.url - The device address.
		* @param {string} params.callback_success - function to callback upon successful completion
		* @param {string} params.callback_failure - function to callback upon failed completion
		* @param {number} [params.timeout=0] - amount of seconds to wait before calling 
		*									the callback_failure routine (0 = no timeout)
		* @return {string} Blank string or comm error beginning with "FAILURE"
		*
		*/			
		xrxAccessConfigGetApplicationFeatureAccessConfigInfo: function( params ) {
			var url = params.url || DEFAULT_URL;
			var sendUrl = url + XRX_ACCESSCONFIG_PATH;
			var sendReq = this.xrxAccessConfigGetApplicationFeatureAccessConfigInfoRequest( sendUrl );
			return xrxCallWebservice( sendUrl, sendReq, params.callback_success, params.callback_failure, params.timeout, null, null, null, true );
		},

		/**
		* This function returns the xrxAccessConfigGetApplicationFeatureAccessConfigInfoRequest
		*
		* @param {string}	sendUrl	The device address with the Access Configuration path
		* @return {string}   The GetApplicationFeatureAccessConfigInfoRequest in string.
		*/		
		xrxAccessConfigGetApplicationFeatureAccessConfigInfoRequest: function( sendUrl ) {
			var result = XRX_SOAP11_SOAPSTART 
					+ xrxCreateTag( 'GetApplicationFeatureAccessConfigInfoRequest', XRX_ACCESSCONFIG_NAMESPACE, '' ) 
					+ XRX_SOAPEND;
			var action = XRX_ACCESSCONFIG_ACTION + "/GetApplicationFeatureAccessConfigInfo";
			var to = sendUrl + "/";
			try {
				result = addWsAddressingHeader( result, action, to );
			} catch(err) {
				console.log("Error in addWsAddressingHeader()" + err.message);
			}
			return result;
		},

		/**
		* This function returns the parsed values.
		*
		* @param {string}	response	web service response in string form
		* @return {array}   an array of AccessInfo
		*/
		xrxAccessConfigParseGetApplicationFeatureAccessConfigInfo: function( response ) {
			var result = new Array();
			
			var list = xrxFindElements( xrxStringToDom( response ), "element" );
			if(list == null) {
				list = new Array();
			}
			var i = 0;
			for(i = 0; i < list.length; i++) {
				var AccessInfo = {};
				AccessInfo.ApplicationName = xrxGetElementValue( list[i], "ApplicationName" );
				AccessInfo.FeatureName = xrxGetElementValue( list[i], "FeatureName" );
				AccessInfo.AccessDisplay = xrxGetElementValue( list[i], "AccessDisplay" );
				AccessInfo.AllowUserList = xrxGetElementValue( list[i], "AllowUserList" );
				result.push( AccessInfo ); 
			}
			return result;
		},

		// SetApplicationFeatureAccessConfigInfo
		
		/**
		* This function sets ApplicationFeatureAccessConfigInfo
		*
		* @function xrxAccessConfigSetApplicationFeatureAccessConfigInfo
		* @param {Object} params - The parameters for calling SetApplicationFeatureAccessConfigInfo
		* @param {string} params.url - The device address.
		* @param {string} params.appName - The application to set the access information of
		* @param {string} params.featureName - The feature of the application to set the access information of
		* @param {string} params.accessType - The new access type information of the application
		* @param {string} params.userList - The new AllowUserList of the application
		* @param {string} params.username	- Admin username or network authentication username.
		* @param {string} params.password	- Admin password or network authentication password.
		* @param {string} [params.passwordType="PasswordDigest"] - Ws-Security password type. 		
		* @param {string} params.callback_success - function to callback upon successful completion
		* @param {string} params.callback_failure - function to callback upon failed completion
		* @param {number} [params.timeout=0] - amount of seconds to wait before calling 
		*									the callback_failure routine (0 = no timeout)
		* @return {string} Blank string or comm error beginning with "FAILURE"
		*
		*/		
		xrxAccessConfigSetApplicationFeatureAccessConfigInfo: function( params ) {
			var url = params.url || DEFAULT_URL;
			var sendUrl = url + XRX_ACCESSCONFIG_PATH;
			var sendReq = this.xrxAccessConfigSetApplicationFeatureAccessConfigInfoRequest( sendUrl, params.appName, params.featureName, params.accessType, params.userList, params.username, params.password, params.passwordType );
			return xrxCallWebservice( sendUrl, sendReq, params.callback_success, params.callback_failure, 
						params.timeout, null, null, null, true );
		},

		/**
		* This function returns the ApplicationFeatureAccessConfigInfo request
		*
		* @param {string} sendUrl	- The device address with the Access Configuration path
		* @param {string} appName - The application to set the access information of.
		* @param {string} featureName - The feature of the application to set the access information of
		* @param {string} accessType - The new access type information of the application
		* @param {string} userList - The new AllowUserList of the application
		* @param {string} username	- Admin username or network authentication username.
		* @param {string} password	- Admin password or network authentication password.
		* @param {string} [passwordType="PasswordDigest"] - Ws-Security password type.
		* @return {string}  The SetAccessConfigInfoRequest in string.
		*/			
		xrxAccessConfigSetApplicationFeatureAccessConfigInfoRequest: function( sendUrl, appName, featureName, accessType, userList, username, password, type ) {
			var result = XRX_SOAP11_SOAPSTART 
					+ xrxCreateTag( 'SetApplicationFeatureAccessConfigInfoRequest', XRX_ACCESSCONFIG_NAMESPACE, 
						xrxCreateTag( 'ApplicationName', '', appName ) +
						xrxCreateTag( 'FeatureName', '', featureName ) +
						xrxCreateTag( 'AccessInfo', '', xrxCreateTag( 'AccessDisplay', '', accessType) + xrxCreateTag( 'AllowUserList', '', userList) ) )
					+ XRX_SOAPEND;
			var action = XRX_ACCESSCONFIG_ACTION + "/SetApplicationFeatureAccessConfigInfo";
			var to = sendUrl + "/";
			try {
				result = addWsSecurityHeader( result, username, password, type );
				result = addWsAddressingHeader( result, action, to );
			} catch(err) {
				console.log("Error in addWsSecurityHeader() or addWsAddressingHeader()" + err.message);
			}
			return result;
		},
	
	};
	return methods;
})();
 
 
 