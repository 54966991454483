// XrxJobLimits.js
// Copyright (C) Xerox Corporation, 2007, 2008, 2009, 2010, 2011, 2012, 2013.  All rights reserved.

// This file encapsulates the functions to call the Job Limits Api Web server.


/****************************  CONSTANTS  *******************************/

var XRX_JOBLIMITS_NAMESPACE = 'xmlns="http://www.xerox.com/webservices/JobLimits/1" ';
var XRX_JOBMODEL_NAMESPACE = 'xmlns="http://schemas.xerox.com/enterprise/eipjobmodel/1" ';
var XRX_JOBLIMITS_NAMESPACE_ESC = 'xmlns=&quot;http://www.xerox.com/webservices/JobLimits/1&quot; ';
var XRX_JOBMODEL_NAMESPACE_ESC = 'xmlns=&quot;http://schemas.xerox.com/enterprise/eipjobmodel/1&quot; ';
var XRX_JOBLIMITS_PATH = '/webservices/JobLimits/1';
var XRX_JOBLIMITS_ACTION = 'http://xml.namespaces.xerox.com/enterprise/JobLimits/1';

/****************************  FUNCTIONS  *******************************/


function xrxJobApprovalUpdate(proceedState, jobId, jobType, jobToken, url, callback_success, callback_failure, async )
{

	if((url === null) || (url === ""))
        url = "http://127.0.0.1";
    var sendUrl = url + XRX_JOBLIMITS_PATH;
    var sendReq = xrxJobApprovalUpdateRequest(sendUrl, proceedState, jobId, jobType, jobToken);
	//log("xrxJobApprovalUpdate", sendReq);
	
	return xrxCallWebservice( sendUrl, sendReq, callback_success, callback_failure, 0, null, null, null, async );
}


function xrxJobApprovalUpdateRequest(url, proceedState, jobId, jobType, jobToken)
{
	var schemaVer = xrxCreateEscapedTag('MajorVersion','','1' ) + xrxCreateEscapedTag('MinorVersion','','2' ) + xrxCreateEscapedTag('Revision','','0' );
	var schemaTag = xrxCreateEscapedTag('schemaVersion','',schemaVer);
	var proceedTag = xrxCreateEscapedTag('ProceedState','',proceedState);
	var jobIdTag = xrxCreateEscapedTag('JobIdentifier',XRX_JOBMODEL_NAMESPACE_ESC, 
		xrxCreateEscapedTag('JobIdentifierType', '', 'JobId') +
        xrxCreateEscapedTag('JobIdentifierString', '', jobId));
	var jobTypeTag = xrxCreateEscapedTag('JobType',XRX_JOBMODEL_NAMESPACE_ESC,jobType);
	var tokenTag = xrxCreateEscapedTag('JobToken_Char16','',jobToken);
	var infoTag = xrxCreateEscapedTag('InformationalScreenInfo','', '');
		//xrxCreateEscapedTag('InformationScreenTitleText', '', '') + 
		//xrxCreateEscapedTag('InformationScreenBodyText','', ''));
	var genTag = xrxCreateEscapedTag('GenerateInfoScreen','','false');
	var confTag = xrxCreateEscapedTag('ConfirmationScreenInfo','',
		xrxCreateEscapedTag('ConfirmationScreenTitleText', '', '')+
        xrxCreateEscapedTag('ConfirmationScreenBodyText','', ''));
	var escPayload = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' + xrxCreateEscapedTag( 'JobApprovalUpdateRequestPayload', 'xmlns:xsd=&quot;http://www.w3.org/2001/XMLSchema&quot; xmlns:xsi=&quot;http://www.w3.org/2001/XMLSchema-instance&quot; '+XRX_JOBLIMITS_NAMESPACE_ESC, 
                proceedTag + jobIdTag + jobTypeTag + tokenTag + infoTag + genTag );
	//log("xrxJobApprovalUpdateRequest", "escPayload:"+escPayload);
    var result =  XRX_SOAPSTART +
        xrxCreateTag( 'JobApprovalUpdateRequest', XRX_JOBLIMITS_NAMESPACE, 
             xrxCreateTag('JobLimitsSchema_JobApprovalUpdateRequestPayload', '', escPayload)) 
        + XRX_SOAPEND;

	//result = addWsSecurity( result, null, null, XRX_JOBLIMITS_ACTION + "/JobApprovalUpdate", url);

	return result;

}

/* *
* This function creates an xml tag in an escaped string.
*
* @param	label		tag
* @param	type		attribute
* @param	value		text value
*/
function xrxCreateEscapedTag(label, type, value) {
    if (type === "") {
        return ("&lt;" + label + "&gt;" + value + "&lt;/" + label + "&gt;");
    }
    else {
        return ("&lt;" + label + " " + type + "&gt;" + value + "&lt;/" + label + "&gt;");
    }
}


//This is for the device simulator

var XRX_SOAPSTART_VERSA = '<?xml version="1.0" encoding="utf-8"?>' +
    '<SOAP-ENV:Envelope' +
    ' xmlns:SOAP-ENV="http://www.w3.org/2003/05/soap-envelope">' +
    '<SOAP-ENV:Body xmlns:xsd="http://www.w3.org/2001/XMLSchema">';
var XRX_SOAPEND_VERSA = '</SOAP-ENV:Body></SOAP-ENV:Envelope>';

var XRX_JOBLIMITS_CLIENT_NAMESPACE = 'xmlns="http://www.xerox.com/webservices/JobLimitsDeviceClient/1" ';
var XRX_JOBLIMITS_CLIENT_PATH = 'JobLimitsAppServer.asmx';


function xrxJobApproval(jobApprovalPayload, url, callback_success, callback_failure, async) {

    if ((url === null) || (url === ""))
        url = "http://127.0.0.1";
    var sendUrl = url + XRX_JOBLIMITS_CLIENT_PATH;
    var sendReq = xrxJobApprovalRequest(sendUrl, jobApprovalPayload);
    var headers = [
        ["Content-Type", "application/soap+xml; charset=utf-8" ],
        ["SOAPAction", '""']];

    return xrxCallWebservice(sendUrl, sendReq, callback_success, callback_failure, 0, headers, null, null, async);
}


function xrxJobApprovalRequest(url, jobApprovalPayload) {
    var schemaVer = xrxCreateTag('MajorVersion', '', '1') + xrxCreateTag('MinorVersion', '', '0') + xrxCreateTag('Revision', '', '4');
    var result = XRX_SOAPSTART_VERSA  +
        xrxCreateTag('JobApprovalRequest', XRX_JOBLIMITS_CLIENT_NAMESPACE,
        xrxCreateTag('InterfaceVersionClient', '', schemaVer) +
        xrxCreateTag('ApplicationTimeout', '', '30') +
        xrxCreateTag('JobLimitsDeviceClientSchema_JobApprovalRequestPayload', '', jobApprovalPayload))
        + XRX_SOAPEND_VERSA;

    return result;

}
