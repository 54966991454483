/* 
 * XrxKiosk.js
 * Copyright (C) Xerox Corporation, 2007, 2008, 2009, 2010, 2011, 2012, 2013.  All rights reserved.
 *
 * This file encapsulates the functions to call the Xerox KIOSK Job Limits Api Web server.
 *

 */

/****************************  CONSTANTS  *******************************/

var XRX_KIOSK_NAMESPACE = 'xmlns="http://www.xerox.com/webservices/JobLimitsDeviceClient/1"';
var XRX_KIOSK_PATH = 'JobLimitsAppServer.asmx';

var XRX_KIOSK_SOAPSTART = '<?xml version="1.0" encoding="utf-8"?>'
	+ '<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" '
	+ 'xmlns:xsd="http://www.w3.org/2001/XMLSchema">'
	+ '<soap:Body>';

var XRX_KIOSK_AUTHSOAPSTART = '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope' +
    ' xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"' +
    ' xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
    ' xmlns:xsd="http://www.w3.org/2001/XMLSchema"' +
    ' xmlns:wsa="http://schemas.xmlsoap.org/ws/2004/08/addressing"' +
    ' xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd"' +
    ' xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">' +
    '<soap:Header>' +
    '</soap:Header>' +
    '<soap:Body>';

var XRX_KIOSK_CONFIGSOAPSTART = '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope' +
    ' xmlns:soap="http://www.w3.org/2003/05/soap-envelope"' +
    ' xmlns:wsa="http://schemas.xmlsoap.org/ws/2004/08/addressing"' +
    ' xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd"' +
    ' xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">' +
    '<soap:Header>' +
    '</soap:Header>' +
    '<soap:Body>';

var XRX_ACCOUNTING_SOAPSTART = '<SetAccountingMethodConfigurationRequest xmlns="http://www.xerox.com/webservices/office/accessconfig/1"> '
    + '<AccessConfig_AccountingMethodConfigurationPayload>';

var XRX_ACCOUNTPAYLOAD_SOAPSTART = '&lt;?xml version="1.0" encoding="utf-8"?&gt;'
    + '&lt;xrx:AccountingMethodConfigurationPayload '
    + 'xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" '
    + 'xmlns:SOAP-ENC="http://schemas.xmlsoap.org/soap/encoding/" '
    + 'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" '
    + 'xmlns:xsd="http://www.w3.org/2001/XMLSchema" '
    + 'xmlns:xop="http://www.w3.org/2004/08/xop/include" '
    + 'xmlns:c14n="http://www.w3.org/2001/10/xml-exc-c14n#" '
    + 'xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd" '
    + 'xmlns:ds="http://www.w3.org/2000/09/xmldsig#" '
    + 'xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd" '
    + 'xmlns:xrx="http://www.xerox.com/webservices/office/accessconfig/1"&gt;';

var XRX_ACCOUNTING_SOAPEND = '</AccessConfig_AccountingMethodConfigurationPayload></SetAccountingMethodConfigurationRequest>';
var XRX_ACCOUNTPAYLOAD_SOAPEND = '&lt;/xrx:AccountingMethodConfigurationPayload&gt;';

var XRX_WSXCONFIG_ACCESS_PATH = '/webservices/office/accessconfig/1';
var XRX_WSXCONFIG_ACCESS_ACTION = 'http://www.xerox.com/webservices/office/accessconfig/1';

var XRX_WSXCONFIG_UI_ACTION = 'http://xml.namespaces.xerox.com/enterprise/XConfig-UI/1';
var XRX_WSXCONFIG_UI_NAMESPACE = 'xmlns="' + XRX_WSXCONFIG_UI_ACTION + '"';

var XRX_WSXCONFIG_UI_PATH = '/webservices/XConfig-UI/01';

//var KIOSK_HOST = "http://aeojoblimits.eastus.cloudapp.azure.com/PrototypeV35/";

/****************************  FUNCTIONS  *******************************/


//  CancelKioskJob


/* 
* This function gets the sends a Cancel request to the server
* @param  (string)  deviceID				serial number of device making the call
* @param  (string)  cancelJobID				JobID to be cancelled
* @param {string}	url					destination address
* @param {string}	callback_success	function to callback upon successful completion
* @param {string} 	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxCancelKioskJob( deviceID, cancelJobID, callback_success, callback_failure, async )
{
    var sendUrl = sessionStorage.getItem("protocol") + localStorage.getItem("serverUrl") + XRX_KIOSK_PATH;
    var sendReq = xrxCancelJobKioskRequest(deviceID, cancelJobID);
    var headers = [["sessionID", sessionStorage.getItem("sessionID")], ["SOAPAction", '""'], ["Content-Type", "text/xml"]];
	return xrxCallWebservice( sendUrl, sendReq, callback_success, callback_failure, 0, headers, null, null, async );
}

/* 
* This function builds the CancelJob request.
*  @param (string) cancelJobID
* @return {string}	xml request
*/
function xrxCancelJobKioskRequest(deviceID, cancelJobID)
{
    var myDeviceIDTag = xrxCreateTag('DeviceId', '', deviceID);
    var myJobIDTag = xrxCreateTag('JobID', '', cancelJobID);

	return	XRX_KIOSK_SOAPSTART 
        + xrxCreateTag('CancelJobRequest', XRX_KIOSK_NAMESPACE, myDeviceIDTag + myJobIDTag)
			+ XRX_SOAPEND;
}
//  CancelJob


/* 
* This function sends the releaseJobID to the JobLImits server to prep for a release message to be sent.
* @param  (string)  deviceID				serial number of device making the call
* @param  (string)  releaseJobID				JobID to be released
* @param {string}	url					destination address
* @param {string}	callback_success	function to callback upon successful completion
* @param {string} 	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxReleaseKioskJob(deviceID, releaseJobID, callback_success, callback_failure, async )
{
    var sendUrl = sessionStorage.getItem("protocol") + localStorage.getItem("serverUrl") + XRX_KIOSK_PATH;
    var sendReq = xrxReleaseKioskJobRequest(deviceID, releaseJobID);
    var headers = [["sessionID", sessionStorage.getItem("sessionID")], ["SOAPAction", '""'], ["Content-Type", "text/xml"]];
	return xrxCallWebservice( sendUrl, sendReq, callback_success, callback_failure, 0, headers, null, null, async );
}

/* 
* This function builds the ReleaseJob request.
*  @param (string) releaseJobID
* @return {string}	xml request
*/
function xrxReleaseKioskJobRequest(deviceID, releaseJobID)
{
    var myDeviceIDTag = xrxCreateTag('DeviceId', '', deviceID);
    var myJobIDTag = xrxCreateTag('JobID','',releaseJobID );
	return	XRX_KIOSK_SOAPSTART 
        + xrxCreateTag('ReleaseJobRequest', XRX_KIOSK_NAMESPACE, myDeviceIDTag + myJobIDTag)
			+ XRX_SOAPEND;
}

/*
* This function sends the estimateJobID to the JobLimits server 
* @param  (string)  deviceID				serial number of device making the call
* @param  (string)  estimateJobID				JobID to be estimated
* @param {string}	url					destination address
* @param {string}	callback_success	function to callback upon successful completion
* @param {string} 	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxCostEstimate(deviceID, estimateJobID, callback_success, callback_failure, async )
{
    var sendUrl = sessionStorage.getItem("protocol") + localStorage.getItem("serverUrl") + XRX_KIOSK_PATH;
    var sendReq = xrxCostEstimateRequest(deviceID, estimateJobID);
    var headers = [["sessionID", sessionStorage.getItem("sessionID")], ["SOAPAction", '""'], ["Content-Type", "text/xml"]];
	return xrxCallWebservice( sendUrl, sendReq, callback_success, callback_failure, 0, headers, null, null, async );
}

/*
* This function builds the CostEstimateJob request.
*  @param (string) estimateJobID
* @return {string}	xml request
*/
function xrxCostEstimateRequest(deviceID, estimateJobID)
{
    var myDeviceIDTag = xrxCreateTag('DeviceId', '', deviceID);
	var myJobIDTag = xrxCreateTag('JobID','',estimateJobID );

	
	return	XRX_KIOSK_SOAPSTART 
        + xrxCreateTag('CostEstimateRequest', XRX_KIOSK_NAMESPACE, myDeviceIDTag + myJobIDTag)
			+ XRX_SOAPEND;
}


/*
* This function returns the parsed values.
*
* @param {string} 	response	web service response in string form
* @return {string}	EstimatedCost
*/
function xrxParseCostEstimate( response )
{
    var Estimate = {
        currentJobCost: "0",
        currentTrxnCost: "0",
        currentJobId: null,
        currentJobToken: null,
        newCardStatus: false
    };
    var data = xrxGetElementValue(xrxStringToDom( response ), "JobLimitsDeviceClientSchema_CostEstimateResponsePayload");
	if (data !== null)
	{
		var unData = xrxUnescape(data);
		//discovery doesn't like garbage in front of the string
		var stripped = unData.substring(unData.search("<"), unData.length);
		var dataPL = xrxStringToDom(stripped);

        Estimate.currentJobCost = xrxGetValue(xrxFindElement(dataPL, ["CostEstimateResponsePayload", "CostEstimate"]));
        Estimate.currentTrxnCost = xrxGetValue(xrxFindElement(dataPL, ["CostEstimateResponsePayload", "TotalCost"]));
        Estimate.currentJobId = xrxGetValue(xrxFindElement(dataPL, ["CostEstimateResponsePayload", "JobId"]));
        Estimate.currentJobToken = xrxGetValue(xrxFindElement(dataPL, ["CostEstimateResponsePayload", "JobToken_Char16"]));
        Estimate.newCardStatus = xrxGetValue(xrxFindElement(dataPL, ["CostEstimateResponsePayload", "NewCardRequired"]));
    }
    return Estimate;

}

function xrxParseCostEstimateForJobId(response) {
    var data = xrxGetElementValue(xrxStringToDom(response), "JobLimitsDeviceClientSchema_CostEstimateResponsePayload");
    if (data !== null) {
        var unData = xrxUnescape(data);
        //discovery doesn't like garbage in front of the string
        var stripped = unData.substring(unData.search("<"), unData.length);
        var dataPL = xrxStringToDom(stripped);

        return xrxGetValue(xrxFindElement(dataPL, ["CostEstimateResponsePayload", "JobId"]));
    }

}

/*
* This function sends the JBA accounting data for the indicated job to the Kiosk server
* @param  (string)  deviceID				serial number of device making the call
* @param  (string)  completedJobID		JobID
* @param {string}	url					destination address
* @param {string}	callback_success	function to callback upon successful completion
* @param {string} 	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxCompletedJob(deviceID, completedJobID, accounting, callback_success, callback_failure, async )
{
    var sendUrl = sessionStorage.getItem("protocol") + localStorage.getItem("serverUrl") + XRX_KIOSK_PATH;
    var sendReq = xrxCompletedJobRequest(deviceID, completedJobID, accounting);
    var headers = [["sessionID", sessionStorage.getItem("sessionID")], ["SOAPAction", '""'], ["Content-Type", "text/xml"]];
	return xrxCallWebservice( sendUrl, sendReq, callback_success, callback_failure, 0, headers, null, null, async );
}

/*
* This function builds the CompletedJob request.
* @param  (string)  deviceID				serial number of device making the call
*  @param (string) completedJobID
*  @param (string) accounting
* @return {string}	xml request
*/
function xrxCompletedJobRequest(deviceID, completedJobID, accounting)
{
    var myDeviceIDTag = xrxCreateTag('DeviceId', '', deviceID);
	var myJobIDTag = xrxCreateTag('JobID','',completedJobID );
	var myAccounting = xrxCreateTag('JBAData','',accounting);

	return	XRX_KIOSK_SOAPSTART 
        + xrxCreateTag('CompletedJobInfoRequest', XRX_KIOSK_NAMESPACE, myDeviceIDTag + myJobIDTag + myAccounting)
			+ XRX_SOAPEND;
}


/*
* This function sends the KioskJobList to the JobLimits server to request the list of jobs for this user
* @param  (string)  deviceID				serial number of device making the call
* @param {string}	url					destination address
* @param {string}	callback_success	function to callback upon successful completion
* @param {string} 	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxKioskJobList(deviceID, callback_success, callback_failure, async)
{
    var sendUrl = sessionStorage.getItem("protocol") + localStorage.getItem("serverUrl") + XRX_KIOSK_PATH;
    var sendReq = xrxKioskJobListRequest(deviceID);
    var headers = [["sessionID", sessionStorage.getItem("sessionID")], ["SOAPAction", '""'], ["Content-Type", "text/xml"]];
	return xrxCallWebservice( sendUrl, sendReq, callback_success, callback_failure, 0, headers, null, null, async );
}

/*
* This function builds the KioskJobList request.
* 
* @return {string}	xml request
*/
function xrxKioskJobListRequest(deviceID)
{
    var myDeviceIDTag = xrxCreateTag('DeviceId', '', deviceID);
	return	XRX_KIOSK_SOAPSTART 
        + xrxCreateTag('KioskJobListRequest', XRX_KIOSK_NAMESPACE, myDeviceIDTag)
			+ XRX_SOAPEND;
}

/*
* This function returns the list of jobs from the KioskJobListRequest response.
*
* @param {string} 	response	web service response in string form
* @return {string}	KioskJobData element
*/
function xrxParseKioskJobData( response )
{
	
	var data = xrxGetElementValue(xrxStringToDom( response ), "JobLimitsDeviceClientSchema_KioskJobListPayload");
	
	if (data !== null)
	{
		var unData = xrxUnescape(data);
		
		//discovery doesn't like garbage in front of the string
		var stripped = unData.substring(unData.search("<"), unData.length);
		var dataPL = xrxStringToDom(stripped);

		return xrxFindElementList( dataPL, ["KioskJobListPayload","KioskJobData"] );
	}
	else {
		return 	null;
	}
}


function xrxFindElementList( root, elements )
{
	var list;
	var node = root;
	for(var i = 0; ((node !== null) && (i < elements.length));++i)
	{
		list = xrxFindElements( node, elements[i] );
        node = ((list !== null) ? list[0] : null);
	}
	return list;
}


/*
* This function returns the TotalCost from the KioskJobListRequest response.
*
* @param {string} 	response	web service response in string form
* @return {string}	TotalCost element
*/
function xrxParseKioskTotalCost( response )
{
    var TotalCost = {
        cost: "0",
        tax: "0",
    };
    var data = xrxGetElementValue(xrxStringToDom( response ), "JobLimitsDeviceClientSchema_KioskJobListPayload");
	if (data !== null)
	{
		var unData = xrxUnescape(data);
		var stripped = unData.substring(unData.search("<"), unData.length);
		var dataPL = xrxStringToDom(stripped);
		
        TotalCost.cost = xrxGetValue(xrxFindElement(dataPL, ["KioskJobListPayload", "CartTotalType"]));
        TotalCost.tax = xrxGetValue(xrxFindElement(dataPL, ["KioskJobListPayload", "CartTotalTax"]));
    }
    
    return TotalCost;
}

/*
* This function sends the Session to the JobLimits server to notify the server of the state of the user's session
* @param  (string)  deviceID				serial number of device making the call
* @param  (string)  currentState		Valid values are: initialize, charge, ended
* @param {string}	url					destination address
* @param {string}	callback_success	function to callback upon successful completion
* @param {string} 	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxKioskSession(deviceID, currentState, callback_success, callback_failure, async)
{
    var sendUrl = sessionStorage.getItem("protocol") + localStorage.getItem("serverUrl") + XRX_KIOSK_PATH;
    var sendReq = xrxKioskSessionRequest(deviceID, currentState, "", "");
    var headers = [["sessionID", sessionStorage.getItem("sessionID")], ["SOAPAction", '""'], ["Content-Type", "text/xml"]];
	return xrxCallWebservice( sendUrl, sendReq, callback_success, callback_failure, 0, headers, null, null, async );
}

/*
* This function sends the Session to the JobLimits server to notify the server of the state of the user's session
* @param  (string)  deviceID				serial number of device making the call
* @param  (string)  currentState		Valid values are: initialize, charge, ended
* @param  (string)  sessionType		    Only used for currentState = initialize. Valid values are: paid, nonPaid
* @param  (string)  sessionKey		    Only used for Simulator only.
* @param {string}	url					destination address
* @param {string}	callback_success	function to callback upon successful completion
* @param {string} 	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxInitKioskSession(deviceID, currentState, sessionType, callback_success, callback_failure, async) {
    var sendUrl = sessionStorage.getItem("protocol") + localStorage.getItem("serverUrl") + XRX_KIOSK_PATH;
    var sendReq = xrxKioskSessionRequest(deviceID, currentState, sessionType);
    var headers = [["PBSecretKey", sessionStorage.getItem("PBSecretKey")], ["SOAPAction", '""'], ["Content-Type", "text/xml"]];
    return xrxCallWebservice(sendUrl, sendReq, callback_success, callback_failure, 0, headers, null, null, async);
}

/*
* This function builds the SessionComplete request.
*  @param (string) serialID
* @return {string}	xml request
*/
function xrxKioskSessionRequest(deviceID, currentState, sessionType )
{
    var myDeviceIDTag = xrxCreateTag('DeviceId', '', deviceID);
    var mySessionTag = xrxCreateTag('SessionKeyword', '', currentState);
    var mySessionTypeTag = xrxCreateTag('sessionType', '', sessionType);
	return	XRX_KIOSK_SOAPSTART 
        + xrxCreateTag('KioskSessionRequest', XRX_KIOSK_NAMESPACE, myDeviceIDTag + mySessionTag + mySessionTypeTag )
			+ XRX_SOAPEND;
}


function xrxParseInitKioskSession(response) {
    var sessionDetails = {
        sessionID: null,
        sessionKey: null
    };

    var data = xrxGetElementValue(xrxStringToDom(response), "JobLimitsDeviceClientSchema_KioskSessionInitResponsePayload");
    if (data !== null) {
        var unData = xrxUnescape(data);
        //discovery doesn't like garbage in front of the string
        var stripped = unData.substring(unData.search("<"), unData.length);
        var dataPL = xrxStringToDom(stripped);

        sessionDetails.sessionID = xrxGetValue(xrxFindElement(dataPL, ["KioskSessionInitResponsePayload", "sessionID"]));
        sessionDetails.sessionKey = xrxGetValue(xrxFindElement(dataPL, ["KioskSessionInitResponsePayload", "sessionKey"]));
    }
    return sessionDetails;
}

/*
* This function returns the Refund from the xrxKioskSessionRequest response.
*
* @param {string} 	response	web service response in string form
* @return {string}	Refund element
*/
function xrxParseSessionRefund( response )
{
    var data = xrxGetElementValue(xrxStringToDom(response), "JobLimitsDeviceClientSchema_KioskSessionChargeResponsePayload");
	if (data !== null)
	{
		data = xrxStringToDom(xrxUnescape(data));
		return xrxGetValue( xrxFindElement( data, ["KioskSessionChargeResponsePayload","CartTotalType"] ) );
	}
}

function xrxParseConnToken(response) {
    var data = xrxGetElementValue(xrxStringToDom(response), "JobLimitsDeviceClientSchema_KioskSessionChargeResponsePayload");
    if (data !== null) {
        var unData = xrxUnescape(data);

        //discovery doesn't like garbage in front of the string
        var stripped = unData.substring(unData.search("<"), unData.length);
        var dataPL = xrxStringToDom(stripped);
        return xrxGetValue(xrxFindElement(dataPL, ["KioskSessionChargeResponsePayload", "CartTotalType"]));
    }
}

/**
*
* This function adds the Ws-Addressing header to the envelope that is passed in
*   
* @param	{string} 	envelope	current soap request
* @param    {string}    action		wsa:Action.
* @param    {string}    to 			wsa:To.
* @return	{string}	Ws-Addressing header.
*/
function addWsAddressingHeader(envelope, action, to) {
    var XRX_ADDRESSING_HEADER = '<wsa:MessageID>[MessageId]</wsa:MessageID>'
        + '<wsa:Action>[Action]</wsa:Action>'
        + '<wsa:ReplyTo><wsa:Address>http://schemas.xmlsoap.org/ws/2004/08/addressing/role/anonymous</wsa:Address></wsa:ReplyTo>'
        + '<wsa:To>[To]</wsa:To>';

    var result = XRX_ADDRESSING_HEADER;

    result = result.replace("[MessageId]", 'urn:uuid:f7f5154b-976a-4ace-a187-390202166a70');
    result = result.replace("[Action]", action);
    result = result.replace("[To]", to);

    return envelope.replace('</soap:Header>', result + '</soap:Header>');
} 

function myAuthEscape(text) {
    return xrxReplaceChars(xrxReplaceChars(text, ">", "&gt;"), "<", "&lt;");
}

/*
* This function convert the MFD Device as Kiosk Device
* @param {string}   url					destination address
* @param {string}   admin				admin username (blank will not be included)
* @param {string}	adminPassword		admin password (blank will not be included)
* @param {string}	deviceID			serial number of device making the call
* @param {string}	deviceType			type of device making the call
* @param {string}	callback_success	function to callback upon successful completion
* @param {string} 	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxConfigAsKioskDevice(url, admin, adminPassword, deviceID, svrUrl, deviceType, callback_success, callback_failure, timeout, async) {
    //logMessage("Info", "xrxConfigAsKioskDevice", "");
    if ((url == null) || (url == ""))
        url = "http://127.0.0.1";
    var sendUrl = url + XRX_WSXCONFIG_ACCESS_PATH;
    var sendReq = deviceType == 'MFD'? xrxConfigAsKioskDeviceRequest(sendUrl, admin, adminPassword, deviceID, svrUrl) : xrxConfigAsKioskSFPRequest(sendUrl, admin, adminPassword, deviceID, svrUrl);
    //logMessage("Info", "xrxConfigAsKioskDeviceRequest - sendReq  {0}".argValues(sendReq), "");
    return xrxCallWebservice(sendUrl, sendReq, callback_success, callback_failure, timeout, null, null, null, async);
}

/*
* This function builds the kiosk convertion request.
* @param {string}   admin				admin username (blank will not be included)
* @param {string}	adminPassword		admin password (blank will not be included)
* @return {string} 	xml request
*/

function xrxConfigAsKioskDeviceRequest(url, admin, adminPassword, deviceID, svrUrl) {
    //logMessage("Info", "xrxConfigAsKioskDeviceRequest", "");

    var XRX_AUTHSTRNG = XRX_KIOSK_AUTHSOAPSTART + XRX_ACCOUNTING_SOAPSTART + XRX_ACCOUNTPAYLOAD_SOAPSTART + myAuthEscape(xrxCreateTag('xrx:AccountingMethods', XRX_XML_TYPE_NONE,
        xrxCreateTag('xrx:JBA', XRX_XML_TYPE_NONE,
            xrxCreateTag('xrx:AccountingMethodEnabled', XRX_XML_TYPE_NONE, "true")
            + xrxCreateTag('xrx:AccountingMethodInfoReadOnly', XRX_XML_TYPE_NONE,
                xrxCreateTag('xrx:AccountingName', XRX_XML_TYPE_NONE, "JobBasedAccounting")
                + xrxCreateTag('xrx:isNative', XRX_XML_TYPE_NONE, "true")
                + xrxCreateTag('xrx:supportsJobLimits', XRX_XML_TYPE_NONE, "true"))
            + xrxCreateTag('xrx:Validation', XRX_XML_TYPE_NONE, "false")
            + xrxCreateTag('xrx:JBAPromptDetails', XRX_XML_TYPE_NONE,
                xrxCreateTag('xrx:DisplayPrompt', XRX_XML_TYPE_NONE, "false")
                + xrxCreateTag('xrx:Label', XRX_XML_TYPE_NONE, "UserID")
                + xrxCreateTag('xrx:DefaultValue', XRX_XML_TYPE_NONE, deviceID)
                + xrxCreateTag('xrx:MaskEntries', XRX_XML_TYPE_NONE, "false")
                + xrxCreateTag('xrx:PromptIndex', XRX_XML_TYPE_NONE, "0"))
            + xrxCreateTag('xrx:JBAPromptDetails', XRX_XML_TYPE_NONE,
                xrxCreateTag('xrx:DisplayPrompt', XRX_XML_TYPE_NONE, "false")
                + xrxCreateTag('xrx:Label', XRX_XML_TYPE_NONE, "AccountID")
                + xrxCreateTag('xrx:DefaultValue', XRX_XML_TYPE_NONE, deviceID)
                + xrxCreateTag('xrx:MaskEntries', XRX_XML_TYPE_NONE, "false")
                + xrxCreateTag('xrx:PromptIndex', XRX_XML_TYPE_NONE, "1"))
            + xrxCreateTag('xrx:JBAByService', XRX_XML_TYPE_NONE,
                xrxCreateTag('xrx:JBAPromptsByService', XRX_XML_TYPE_NONE,
                    xrxCreateTag('xrx:JBAPromptByService', XRX_XML_TYPE_NONE,
                        xrxCreateTag('xrx:PromptOptionName', XRX_XML_TYPE_NONE, "CopyServicePrompt")
                        + xrxCreateTag('xrx:PromptOptionValue', XRX_XML_TYPE_NONE, "Prompt"))
                    + xrxCreateTag('xrx:JBAPromptByService', XRX_XML_TYPE_NONE,
                        xrxCreateTag('xrx:PromptOptionName', XRX_XML_TYPE_NONE, "PrintServicePrompt")
                        + xrxCreateTag('xrx:PromptOptionValue', XRX_XML_TYPE_NONE, "Prompt"))
                    + xrxCreateTag('xrx:JBAPromptByService', XRX_XML_TYPE_NONE,
                        xrxCreateTag('xrx:PromptOptionName', XRX_XML_TYPE_NONE, "ScanServicePrompt")
                        + xrxCreateTag('xrx:PromptOptionValue', XRX_XML_TYPE_NONE, "Prompt"))
                    + xrxCreateTag('xrx:JBAPromptByService', XRX_XML_TYPE_NONE,
                        xrxCreateTag('xrx:PromptOptionName', XRX_XML_TYPE_NONE, "FaxServicePrompt")
                        + xrxCreateTag('xrx:PromptOptionValue', XRX_XML_TYPE_NONE, "Prompt")))))
        + xrxCreateTag('xrx:XSA', XRX_XML_TYPE_NONE,
            xrxCreateTag('xrx:AccountingMethodEnabled', XRX_XML_TYPE_NONE, "false")
            + xrxCreateTag('xrx:AccountingMethodInfoReadOnly', XRX_XML_TYPE_NONE,
                xrxCreateTag('xrx:AccountingName', XRX_XML_TYPE_NONE, "XeroxStandardAccounting")
                + xrxCreateTag('xrx:isNative', XRX_XML_TYPE_NONE, "true")
                + xrxCreateTag('xrx:supportsJobLimits', XRX_XML_TYPE_NONE, "false"))
            + xrxCreateTag('xrx:ShowCustomerAccountNumber', XRX_XML_TYPE_NONE, "false"))
        + xrxCreateTag('xrx:FDI', XRX_XML_TYPE_NONE,
            xrxCreateTag('xrx:AccountingMethodEnabled', XRX_XML_TYPE_NONE, "false")
            + xrxCreateTag('xrx:AccountingMethodInfoReadOnly', XRX_XML_TYPE_NONE,
                xrxCreateTag('xrx:AccountingName', XRX_XML_TYPE_NONE, "AuxillaryInterface")
                + xrxCreateTag('xrx:isNative', XRX_XML_TYPE_NONE, "true")
                + xrxCreateTag('xrx:supportsJobLimits', XRX_XML_TYPE_NONE, "false"))))
        + xrxCreateTag('xrx:JobLimitsSettings', XRX_XML_TYPE_NONE,
            xrxCreateTag('xrx:JobLimitsMethodSelected', XRX_XML_TYPE_NONE, "JobBasedAccounting")
            + xrxCreateTag('xrx:AuthorizationType', XRX_XML_TYPE_NONE,
                xrxCreateTag('xrx:CopyJob', XRX_XML_TYPE_NONE, "PreAuth")
                + xrxCreateTag('xrx:PrintJob', XRX_XML_TYPE_NONE, "PreAuth"))
            + xrxCreateTag('xrx:AdditionalAuthorizationTypes', XRX_XML_TYPE_NONE,
                xrxCreateTag('xrx:AdditionalAuthorizationType', XRX_XML_TYPE_NONE,
                    xrxCreateTag('xrx:AuthorizationName', XRX_XML_TYPE_NONE, "ScanJob")
                    + xrxCreateTag('xrx:AuthorizationValue', XRX_XML_TYPE_NONE, "PreAuth"))
                + xrxCreateTag('xrx:AdditionalAuthorizationType', XRX_XML_TYPE_NONE,
                    xrxCreateTag('xrx:AuthorizationName', XRX_XML_TYPE_NONE, "EMailJob")
                    + xrxCreateTag('xrx:AuthorizationValue', XRX_XML_TYPE_NONE, "PreAuth")))
            + xrxCreateTag('xrx:ServerURL', XRX_XML_TYPE_NONE, svrUrl)
            + xrxCreateTag('xrx:Timeout', XRX_XML_TYPE_NONE, "30")
            + xrxCreateTag('xrx:PrintErrorHandling', XRX_XML_TYPE_NONE, "None"))
        + xrxCreateTag('xrx:USBAccess', XRX_XML_TYPE_NONE,
            xrxCreateTag('xrx:PrintFromUSB', XRX_XML_TYPE_NONE, "true")
            + xrxCreateTag('xrx:ScanToUSB', XRX_XML_TYPE_NONE, "true")));

    var result = XRX_AUTHSTRNG + XRX_ACCOUNTPAYLOAD_SOAPEND + XRX_ACCOUNTING_SOAPEND + XRX_SOAPEND;
    var action = XRX_WSXCONFIG_ACCESS_ACTION + "/SetAccountingMethodConfiguration";
    var to = url + "/";
    var type = "PasswordText";
    try {
        if (((admin != null) && (admin != "")) && ((adminPassword != null) && (adminPassword != ""))) {
            result = addWsSecurityHeader(result, admin, adminPassword, type);
            result = addWsAddressingHeader(result, action, to);
        }
    }
    catch (e) { }
    return result;
}

/*
* This function builds the kiosk convertion request for SFP Device.
* @param {string}   admin				admin username (blank will not be included)
* @param {string}	adminPassword		admin password (blank will not be included)
* @return {string} 	xml request
*/

function xrxConfigAsKioskSFPRequest(url, admin, adminPassword, deviceID, svrUrl) {
  //logMessage("Info", "xrxConfigAsKioskDeviceRequest", "");

  var XRX_AUTHSTRNG = XRX_KIOSK_AUTHSOAPSTART + XRX_ACCOUNTING_SOAPSTART + XRX_ACCOUNTPAYLOAD_SOAPSTART + myAuthEscape(xrxCreateTag('xrx:AccountingMethods', XRX_XML_TYPE_NONE,
      xrxCreateTag('xrx:JBA', XRX_XML_TYPE_NONE,
          xrxCreateTag('xrx:AccountingMethodEnabled', XRX_XML_TYPE_NONE, "true")
          + xrxCreateTag('xrx:AccountingMethodInfoReadOnly', XRX_XML_TYPE_NONE,
              xrxCreateTag('xrx:AccountingName', XRX_XML_TYPE_NONE, "JobBasedAccounting")
              + xrxCreateTag('xrx:isNative', XRX_XML_TYPE_NONE, "true")
              + xrxCreateTag('xrx:supportsJobLimits', XRX_XML_TYPE_NONE, "true"))
          + xrxCreateTag('xrx:Validation', XRX_XML_TYPE_NONE, "false")
          + xrxCreateTag('xrx:JBAPromptDetails', XRX_XML_TYPE_NONE,
              xrxCreateTag('xrx:DisplayPrompt', XRX_XML_TYPE_NONE, "false")
              + xrxCreateTag('xrx:Label', XRX_XML_TYPE_NONE, "UserID")
              + xrxCreateTag('xrx:DefaultValue', XRX_XML_TYPE_NONE, deviceID)
              + xrxCreateTag('xrx:MaskEntries', XRX_XML_TYPE_NONE, "false")
              + xrxCreateTag('xrx:PromptIndex', XRX_XML_TYPE_NONE, "0"))
          + xrxCreateTag('xrx:JBAPromptDetails', XRX_XML_TYPE_NONE,
              xrxCreateTag('xrx:DisplayPrompt', XRX_XML_TYPE_NONE, "false")
              + xrxCreateTag('xrx:Label', XRX_XML_TYPE_NONE, "AccountID")
              + xrxCreateTag('xrx:DefaultValue', XRX_XML_TYPE_NONE, deviceID)
              + xrxCreateTag('xrx:MaskEntries', XRX_XML_TYPE_NONE, "false")
              + xrxCreateTag('xrx:PromptIndex', XRX_XML_TYPE_NONE, "1"))
          + xrxCreateTag('xrx:JBAByService', XRX_XML_TYPE_NONE,
              xrxCreateTag('xrx:JBAPromptsByService', XRX_XML_TYPE_NONE,
                   xrxCreateTag('xrx:JBAPromptByService', XRX_XML_TYPE_NONE,
                      xrxCreateTag('xrx:PromptOptionName', XRX_XML_TYPE_NONE, "PrintServicePrompt")
                      + xrxCreateTag('xrx:PromptOptionValue', XRX_XML_TYPE_NONE, "Prompt")))))
      + xrxCreateTag('xrx:XSA', XRX_XML_TYPE_NONE,
          xrxCreateTag('xrx:AccountingMethodEnabled', XRX_XML_TYPE_NONE, "false")
          + xrxCreateTag('xrx:AccountingMethodInfoReadOnly', XRX_XML_TYPE_NONE,
              xrxCreateTag('xrx:AccountingName', XRX_XML_TYPE_NONE, "XeroxStandardAccounting")
              + xrxCreateTag('xrx:isNative', XRX_XML_TYPE_NONE, "true")
              + xrxCreateTag('xrx:supportsJobLimits', XRX_XML_TYPE_NONE, "false"))
          + xrxCreateTag('xrx:ShowCustomerAccountNumber', XRX_XML_TYPE_NONE, "false"))
      + xrxCreateTag('xrx:FDI', XRX_XML_TYPE_NONE,
          xrxCreateTag('xrx:AccountingMethodEnabled', XRX_XML_TYPE_NONE, "false")
          + xrxCreateTag('xrx:AccountingMethodInfoReadOnly', XRX_XML_TYPE_NONE,
              xrxCreateTag('xrx:AccountingName', XRX_XML_TYPE_NONE, "AuxillaryInterface")
              + xrxCreateTag('xrx:isNative', XRX_XML_TYPE_NONE, "true")
              + xrxCreateTag('xrx:supportsJobLimits', XRX_XML_TYPE_NONE, "false"))))
      + xrxCreateTag('xrx:JobLimitsSettings', XRX_XML_TYPE_NONE,
          xrxCreateTag('xrx:JobLimitsMethodSelected', XRX_XML_TYPE_NONE, "JobBasedAccounting")
          + xrxCreateTag('xrx:AuthorizationType', XRX_XML_TYPE_NONE,
              xrxCreateTag('xrx:CopyJob', XRX_XML_TYPE_NONE, "None")
             + xrxCreateTag('xrx:PrintJob', XRX_XML_TYPE_NONE, "PreAuth"))
          + xrxCreateTag('xrx:AdditionalAuthorizationTypes', XRX_XML_TYPE_NONE,
              xrxCreateTag('xrx:AdditionalAuthorizationType', XRX_XML_TYPE_NONE,
                  xrxCreateTag('xrx:AuthorizationName', XRX_XML_TYPE_NONE, "ScanJob")
                  + xrxCreateTag('xrx:AuthorizationValue', XRX_XML_TYPE_NONE, "None"))
              + xrxCreateTag('xrx:AdditionalAuthorizationType', XRX_XML_TYPE_NONE,
                  xrxCreateTag('xrx:AuthorizationName', XRX_XML_TYPE_NONE, "EMailJob")
                  + xrxCreateTag('xrx:AuthorizationValue', XRX_XML_TYPE_NONE, "None")))
          + xrxCreateTag('xrx:ServerURL', XRX_XML_TYPE_NONE, svrUrl)
          + xrxCreateTag('xrx:Timeout', XRX_XML_TYPE_NONE, "30")
          + xrxCreateTag('xrx:PrintErrorHandling', XRX_XML_TYPE_NONE, "None"))
      + xrxCreateTag('xrx:USBAccess', XRX_XML_TYPE_NONE,
          xrxCreateTag('xrx:PrintFromUSB', XRX_XML_TYPE_NONE, "true")
          + xrxCreateTag('xrx:ScanToUSB', XRX_XML_TYPE_NONE, "false")));

  var result = XRX_AUTHSTRNG + XRX_ACCOUNTPAYLOAD_SOAPEND + XRX_ACCOUNTING_SOAPEND + XRX_SOAPEND;
  var action = XRX_WSXCONFIG_ACCESS_ACTION + "/SetAccountingMethodConfiguration";
  var to = url + "/";
  var type = "PasswordText";
  try {
      if (((admin != null) && (admin != "")) && ((adminPassword != null) && (adminPassword != ""))) {
          result = addWsSecurityHeader(result, admin, adminPassword, type);
          result = addWsAddressingHeader(result, action, to);
      }
  }
  catch (e) { }
  return result;
}

/*
* This function sets the default application.
*
* @param {string}	url					destination address
* @param {string}	pathway				name of pathway
* @param {string}	admin				admin username (blank will not be included)
* @param {string}	adminPassword		admin password (blank will not be included)
* @param {string}	callback_success	function to callback upon successful completion
* @param {string}	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxConfigSetPathwayDefaultApplication(url, pathway, name, vendor, isNative, admin, adminPassword, callback_success, callback_failure, timeout, async) {
    if ((url == null) || (url == ""))
        url = "http://127.0.0.1";
    var sendUrl = url + XRX_WSXCONFIG_UI_PATH;
    var sendReq = xrxConfigSetPathwayDefaultApplicationRequest(pathway, name, vendor, isNative, admin, adminPassword, sendUrl);
    //logMessage("Info", "xrxConfigSetPathwayDefaultApplicationRequest - sendReq  {0}".argValues(sendReq), "");
    return xrxCallWebservice(sendUrl, sendReq, callback_success, callback_failure, timeout, null, null, null, async);
}

/*
* This function builds the request.
*
* @param {string}	admin				admin username (blank will not be included)
* @param {string}	adminPassword		admin password (blank will not be included)
* @return {string} 	xml request
*/
function xrxConfigSetPathwayDefaultApplicationRequest(pathway, name, vendor, isNative, admin, adminPassword, url) {
    var result = XRX_KIOSK_CONFIGSOAPSTART
        + xrxCreateTag('SetPathwayDefaultApplicationRequest', XRX_WSXCONFIG_UI_NAMESPACE,
            xrxCreateTag('Pathway', XRX_XML_TYPE_NONE,
                xrxCreateTag('Name', XRX_XML_TYPE_NONE, pathway))
            + xrxCreateTag('Application', XRX_XML_TYPE_NONE,
                xrxCreateTag('Name', XRX_XML_TYPE_NONE, name)
                + xrxCreateTag('Vendor', XRX_XML_TYPE_NONE, vendor)
                + xrxCreateTag('IsNative', XRX_XML_TYPE_NONE, isNative)))
        + XRX_SOAPEND;
    var action = XRX_WSXCONFIG_UI_ACTION + "/SetPathwayDefaultApplication";
    var to = url + "/";
    var type = "PasswordText";
    try {
        if (((admin != null) && (admin != "")) && ((adminPassword != null) && (adminPassword != ""))) {
            result = addWsSecurityHeader(result, admin, adminPassword, type);
            result = addWsAddressingHeader(result, action, to);
        }
    }
    catch (e) { }
    return result;
}

//  GetPathwayDefaultApplication

/*
* This function gets the default application for the given pathway.
*
* @param {string}	url					destination address
* @param {string}	pathway				given pathway
* @param {string}	admin				admin username (blank will not be included)
* @param {string}	adminPassword		admin password (blank will not be included)
* @param {string}	callback_success	function to callback upon successful completion
* @param {string}	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxConfigGetPathwayDefaultApplication(url, pathway, admin, adminPassword, callback_success, callback_failure, timeout, async) {
    if ((url == null) || (url == ""))
        url = "http://127.0.0.1";
    var sendUrl = url + XRX_WSXCONFIG_UI_PATH;
    var sendReq = xrxConfigGetPathwayDefaultApplicationRequest(pathway, admin, adminPassword, sendUrl);
    //logMessage("Info", "xrxConfigGetPathwayDefaultApplicationRequest - sendReq  {0}".argValues(sendReq), "");
    return xrxCallWebservice(sendUrl, sendReq, callback_success, callback_failure, timeout, null, null, null, async);
}

/*
* This function builds the request.
*
* @param {string}	pathway				given pathway
* @param {string}	admin				admin username (blank will not be included)
* @param {string}	adminPassword		admin password (blank will not be included)
* @param {string}	url					destination address
* @return {string}  xml request
*/
function xrxConfigGetPathwayDefaultApplicationRequest(pathway, admin, adminPassword, url) {
    var result = XRX_KIOSK_CONFIGSOAPSTART
        + xrxCreateTag('GetPathwayDefaultApplicationRequest', XRX_WSXCONFIG_UI_NAMESPACE,
            xrxCreateTag('Pathway', XRX_XML_TYPE_NONE,
                xrxCreateTag('Name', XRX_XML_TYPE_NONE, pathway)))
        + XRX_SOAPEND;
    var action = XRX_WSXCONFIG_UI_ACTION + "/GetPathwayDefaultApplication";
    var to = url + "/";
    var type = "PasswordText";
    try {
        if (((admin != null) && (admin != "")) && ((adminPassword != null) && (adminPassword != ""))) {
            result = addWsSecurityHeader(result, admin, adminPassword, type);
            result = addWsAddressingHeader(result, action, to);
        }
    }
    catch (e) { }
    return result;
}

/*
* This function returns the parsed values.
*
* @param {string}	response	web service response in string form
* @return {object}	Dom object containing application info
*/
function xrxConfigParseGetPathwayDefaultApplication(response) {
    var application = {
        name: null,
        vendor: null,
        isNative: false
    };
    application.name = xrxGetValue(xrxFindElement(xrxStringToDom(response), ["GetPathwayDefaultApplicationResponse", "Name"]));
    application.vendor = xrxGetValue(xrxFindElement(xrxStringToDom(response), ["GetPathwayDefaultApplicationResponse", "Vendor"]));
    application.isNative = xrxGetValue(xrxFindElement(xrxStringToDom(response), ["GetPathwayDefaultApplicationResponse", "IsNative"]));
    return application;
}

/*
* This function gets the Accounting Method Configuration for the destination address.
*
* @param {string}	url					destination address
* @param {string}	admin				admin username (blank will not be included)
* @param {string}	adminPassword		admin password (blank will not be included)
* @param {string}	callback_success	function to callback upon successful completion
* @param {string}	callback_failure	function to callback upon failed completion
* @param {number}	[timeout=0]			amount of seconds to wait before calling 
*										the callback_failure routine (0 = no timeout)
* @param {boolean}  [async=true]        make asynchronous call if true,
* 										make synchronous call if false
* @return {string} 	Blank string or comm error beginning with "FAILURE" if async == true,
*           		response or comm error beginning with "FAILURE" if async == false.
*/
function xrxGetAccountingMethodConfig(url, admin, adminPassword, callback_success, callback_failure, timeout, async) {
    //logMessage("Info", "xrxGetAccountingMethodConfig", "");
    if ((url == null) || (url == ""))
        url = "http://127.0.0.1";
    var sendUrl = url + XRX_WSXCONFIG_ACCESS_PATH;
    var sendReq = xrxGetAccountingMethodConfigRequest(sendUrl, admin, adminPassword);
    //logMessage("Info", "xrxGetAccountingMethodConfigRequest - sendReq  {0}".argValues(sendReq), "");
    return xrxCallWebservice(sendUrl, sendReq, callback_success, callback_failure, timeout, null, null, null, async);
}

/*
* This function builds the request.
*
* @param {string}	url					destination address
* @param {string}	admin				admin username (blank will not be included)
* @param {string}	adminPassword		admin password (blank will not be included)
* @return {string}  xml request
*/
function xrxGetAccountingMethodConfigRequest(url, admin, adminPassword) {

    //logMessage("Info", "xrxGetAccountingMethodConfigRequest", "");
    var XRX_KIOSK_GETAUTHREQ = '<VoidRequest xmlns="http://www.xerox.com/webservices/office/accessconfig/1" />';
    var result = XRX_KIOSK_AUTHSOAPSTART + XRX_KIOSK_GETAUTHREQ + XRX_SOAPEND;
    var action = XRX_WSXCONFIG_ACCESS_ACTION + "/GetAccountingMethodConfiguration";
    var to = url + "/";
    var type = "PasswordText";
    try {
        if (((admin != null) && (admin != "")) && ((adminPassword != null) && (adminPassword != ""))) {
            result = addWsSecurityHeader(result, admin, adminPassword, type);
            result = addWsAddressingHeader(result, action, to);
        }
    }
    catch (e) { }
    return result;
}

/*
* This function returns the parsed values.
*
* @param {string}	response	web service response in string form
* @return {object}	Dom object containing application info
*/
function xrxParseAccountingMethodConfig(response) {
    var extraTag = '&lt;?xml version="1.0" encoding="UTF-8"?&gt;';
   // var data = xrxGetElementValue(xrxStringToDom(response), "eipaccessconfig:GetAccountingMethodConfigurationResponse");
   // logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - response  {0}".argValues(data), "");
    var accountdetails = {
        accMethod: false,
        accName: null,
        Native:false,
        supJobLmt: false,
        accVaild: false,
        jobLmtMet: null,
        copy: null,
        print: null,
        serURL: null,
        acctprptOptName: null,
        acctprptOptValue: null,
        acctjbadisplay: null,
        acctjbalabel: null,
        acctjbadefult: null,
        acctjbamask: null,
        acctauthoriName: null,
        acctauthoriValue: null
    };
    //For Discovery devices version tag is added twice so removing the extra tag here.
    response = response.replace(extraTag, '');
    var unData = xrxUnescape(response);
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - unData  {0}".argValues(unData), "");
    var stripped = unData.substring(unData.search("<"), unData.length);
    var dataPL = xrxStringToDom(stripped);

    accountdetails.accMethod = xrxGetValue(xrxFindElement(dataPL, ["JBA", "AccountingMethodEnabled"]));
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - AccountingMethodEnabled  {0}".argValues(accountdetails.accMethod), "");

    accountdetails.accName = xrxGetValue(xrxFindElement(dataPL, ["JBA", "AccountingName"]));
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - AccountingName  {0}".argValues(accountdetails.accName), "");

    accountdetails.Native = xrxGetValue(xrxFindElement(dataPL, ["JBA", "isNative"]))
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - isNative  {0}".argValues(accountdetails.Native), "");

    accountdetails.supJobLmt = xrxGetValue(xrxFindElement(dataPL, ["JBA", "supportsJobLimits"]));
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - supportsJobLimits  {0}".argValues(accountdetails.supJobLmt), "");

    accountdetails.accVaild = xrxGetValue(xrxFindElement(dataPL, ["JBA", "Validation"]));
   // logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - Validation  {0}".argValues(accountdetails.accVaild), "");

    accountdetails.jobLmtMet = xrxGetValue(xrxFindElement(dataPL, ["JobLimitsSettings", "JobLimitsMethodSelected"]));
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - JobLimitsMethodSelected  {0}".argValues(accountdetails.jobLmtMet), "");

    accountdetails.copy = xrxGetValue(xrxFindElement(dataPL, ["AuthorizationType", "CopyJob"]));
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - AuthorizationType CopyJob {0}".argValues(accountdetails.copy), "");

    accountdetails.print = xrxGetValue(xrxFindElement(dataPL, ["AuthorizationType", "PrintJob"]));
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - AuthorizationType PrintJob {0}".argValues(accountdetails.print), "");

    accountdetails.serURL = xrxGetValue(xrxFindElement(dataPL, ["JobLimitsSettings", "ServerURL"]));
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - ServerURL  {0}".argValues(accountdetails.serURL), "");

    var prptOptName = {};
    var prptOptValue = {};
    var serviceattrList = xrxFindElements(dataPL, 'JBAPromptByService');
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - JBAPromptByService.length  {0}".argValues(serviceattrList.length), "");

    for (var i = 0; i < serviceattrList.length; i++) {
        prptOptName[i] = xrxGetValue(xrxFindElement(serviceattrList[i], ['PromptOptionName']));
        //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - attrName1  {0}".argValues(prptOptName[i]), "");
        prptOptValue[i] = xrxGetValue(xrxFindElement(serviceattrList[i], ['PromptOptionValue']));
        //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - attrName2  {0}".argValues(prptOptValue[i]), "");
    }

    accountdetails.acctprptOptName = prptOptName;
    accountdetails.acctprptOptValue = prptOptValue;

    var jbaprptattrList = xrxFindElements(dataPL, 'JBAPromptDetails');
    //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - JBAPromptDetails.length  {0}".argValues(jbaprptattrList.length), "");

    var jbadisplay = {};
    var jbalabel = {};
    var jbadefult = {};
    var jbamask = {};

    for (var j = 0; j < jbaprptattrList.length; j++) {
        jbadisplay[j] = xrxGetValue(xrxFindElement(jbaprptattrList[j], ['DisplayPrompt']));
        //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - jbadisplay  {0}".argValues(jbadisplay[j]), "");
        jbalabel[j] = xrxGetValue(xrxFindElement(jbaprptattrList[j], ['Label']));
        //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - jbalabel  {0}".argValues(jbalabel[j]), "");
        jbadefult[j] = xrxGetValue(xrxFindElement(jbaprptattrList[j], ['DefaultValue']));
        //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - jbadefult  {0}".argValues(jbadefult[j]), "");
        jbamask[j] = xrxGetValue(xrxFindElement(jbaprptattrList[j], ['MaskEntries']));
        //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - jbamask  {0}".argValues(jbamask[j]), "");
    }

    accountdetails.acctjbadisplay = jbadisplay;
    accountdetails.acctjbalabel = jbalabel;
    accountdetails.acctjbadefult = jbadefult;
    accountdetails.acctjbamask = jbamask;

    var authoriName = {};
    var authoriValue = {};
    var authoriattrList = xrxFindElements(dataPL, 'AdditionalAuthorizationType');
   // logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - AdditionalAuthorizationType.length  {0}".argValues(authoriattrList.length), "");

    for (var k = 0; k < authoriattrList.length; k++) {
        authoriName[k] = xrxGetValue(xrxFindElement(authoriattrList[k], ['AuthorizationName']));
        //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - attrName1  {0}".argValues(authoriName[k]), "");
        authoriValue[k] = xrxGetValue(xrxFindElement(authoriattrList[k], ['AuthorizationValue']));
        //logMessage("Info", "XRXKiosk.js xrxParseAccountingMethodConfig - attrName2  {0}".argValues(authoriValue[k]), "");
    }

    accountdetails.acctauthoriName = authoriName;
    accountdetails.acctauthoriValue = authoriValue;

    return accountdetails;
}